<template>
    <div class="contatti" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
        <div class="cntContatti">
            <div class="cntNumeri">
                <div class="stringContatti">
                    Per qualsiasi informazione potrai contattarci ai nostri numeri che troverai dietro ad ogni foto.❤️
                </div>
                <div class="numeri">
                    <FlipCard :contatto="numeriSposa" width="300px" height="300px" :isAnimated="true" />
                    <div class="sposi">
                        <div>Stella</div>
                        <div style="margin: -4rem 0">&#38;</div>
                        <div>Ale</div>
                    </div>
                    <FlipCard :contatto="numeriSposo" width="300px" height="300px" :isAnimated="true" />
                </div>
                <div class="stringContatti testimoni">
                    Potete invece contattare i testimoni se volete organizzare scherzi, giochi o qualunque altra diavoleria vi passi per la mente! 🎉😱🎉
                </div>

                <v-tabs v-model="tab" background-color="transparent" dark class="tabs">
                    <v-tab v-for="item in itemsTab" :key="item">
                        {{ item }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div class="numeri testimoni">
                            <FlipCard :contatto="contatto" v-for="(contatto, index) in numeriTestimoniSposa" :key="index" width="200px" height="200px" :isAnimated="false" />
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="numeri testimoni">
                            <FlipCard :contatto="contatto" v-for="(contatto, index) in numeriTestimoniSposo" :key="index" width="200px" height="200px" :isAnimated="false" />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
        <img class="imgFiore" src="../assets/green-flower.png" />
    </div>
</template>

<script>
import FlipCard from "../components/FlipCard";
export default {
    components: {
        FlipCard
    },

    head: {
        title: {
            inner: "Contatti"
        }
    },

    data() {
        return {
            tab: null,

            itemsTab: ["Testimoni della Sposa", "Testimoni dello Sposo"],

            numeriSposa: {
                ruolo: "Sposa",
                nome: "Stella",
                telefono: "3911046345",
                src: require("../assets/stella.jpg")
            },
            numeriSposo: {
                ruolo: "Sposo",
                nome: "Alessandro",
                telefono: "3928453360",
                src: require("../assets/alessandro.jpg")
            },

            numeriTestimoniSposa: [
                {
                    //ruolo: "Testimone Sposa",
                    nome: "Serena",
                    telefono: "3460818155",
                    src: require("../assets/serena.jpg")
                },
                {
                    //ruolo: "Testimone Sposa",
                    nome: "Noemi",
                    telefono: "3295859446",
                    src: require("../assets/noemi.jpg")
                },
                {
                    //ruolo: "Testimone Sposa",
                    nome: "Denise",
                    telefono: "3347880159",
                    src: require("../assets/denise.jpg")
                }
            ],

            numeriTestimoniSposo: [
                {
                    //ruolo: "Testimone Sposo",
                    nome: "Riccardo",
                    telefono: "3772214243",
                    src: require("../assets/riccardo.jpg")
                },
                {
                    //ruolo: "Testimone Sposo",
                    nome: "Diletta",
                    telefono: "3348980907",
                    src: require("../assets/diletta.jpg")
                },
                {
                    //ruolo: "Testimone Sposo",
                    nome: "Danilo",
                    telefono: "3291168709",
                    src: require("../assets/danilo.jpg")
                }
            ]
        };
    }
};
</script>

<style lang="scss">
.contatti {
    background: linear-gradient(180deg, rgb(129, 148, 255) 0%, rgb(166, 198, 245) 35%, rgb(191, 224, 255) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .cntContatti {
        margin: 80px;
        display: flex;
        .cntNumeri {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .stringContatti {
                text-align: center;
                font-size: 22px;
            }
            .stringContatti.testimoni {
                margin-top: 3rem;
            }
            .numeri {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 60px;
                width: 100%;
                .sposi {
                    text-align: center;
                    font-family: "Qwitcher Grypen", cursive !important;
                    font-size: 6rem;
                    font-weight: bold;
                    margin: 0 2rem;
                }
            }
            .tabs {
                margin-top: 2rem;
                .v-slide-group__content {
                    justify-content: center;
                }
            }
            .theme--light.v-tabs-items {
                background-color: transparent !important;
                width: 100%;
                .numeri.testimoni {
                    justify-content: space-evenly;
                }
            }
        }
    }
}

.contatti.phone {
    .cntContatti {
        margin: 80px 20px 20px 20px;
        flex-wrap: wrap;
        .cntNumeri {
            margin-left: 0;
            margin-top: 25px;
            .numeri {
                flex-direction: column;
                .cntNumero {
                    margin-bottom: 50px;
                }
            }
            .imgContatto {
                width: 350px !important;
                height: 350px !important;
                padding: 10px;
            }
            .tabs {
                .v-tab {
                    font-size: 10px !important;
                }
            }
        }
    }
}
</style>
