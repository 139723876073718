<template>
    <div class="flip-card" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''" :style="'width: ' + width + '; height: ' + height">
        <div class="flip-card-inner">
            <div class="flip-card-front" :style="'background-image: url(' + contatto.src + ')'"></div>
            <div class="flip-card-back" :style="'background-image: url(' + contatto.src + ')'">
                <div class="contatto">
                    <div class="ruolo">{{ contatto.ruolo }}</div>
                    <div class="nome">{{ contatto.nome }}</div>
                    <div class="numero">{{ contatto.telefono }}</div>
                </div>
            </div>
            <div v-if="isAnimated" class="cata-effect cata-effect-sparkle" :style="'background-image: url(' + effectSparkle1 + '), url(' + effectSparkle2 + ')'"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["contatto", "width", "height", "isAnimated"],
    data() {
        return {
            effectSparkle1: require("../assets/effect-sparkle1.png"),
            effectSparkle2: require("../assets/effect-sparkle2.png"),
        };
    }
};
</script>

<style lang="scss">
.flip-card {
    background-color: transparent;
    margin: 10px;
    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        .flip-card-front,
        .flip-card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border-radius: 150px;
            background-size: 100% 100%;
        }
        .flip-card-front {
            background-color: #bbb;
            color: black;
            filter: brightness(0.75);
        }

        .flip-card-back {
            color: white;
            transform: rotateY(180deg);
            filter: grayscale(1);
            .contatto {
                padding: 10px;
                color: #000;
                align-self: stretch;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 150px;
                color: #fff;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                font-family: "Alex Brush", cursive !important;
                .ruolo {
                    font-size: 14px;
                }
                .nome {
                    font-size: 26px;
                }
                .numero {
                    font-size: 16px;
                }
            }
        }
        .cata-effect {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            overflow: hidden;
            background-color: transparent;
            border-radius: 150px;
        }
    }
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card.phone {
    .ruolo {
        font-size: 13px !important;
    }
    .nome {
        font-size: 22px !important;
    }
    .numero {
        font-size: 14px !important;
    }
}
</style>
